import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { DaySmartCookieService } from '../daySmartCookie/day-smart-cookie.service';

export interface Tokens {
    idToken?: string;
    accessToken?: string;
    refreshToken?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthTokenService {
    private readonly _idTokenKey = 'DSI_Id_Token';
    private readonly _refreshTokenKey = 'DSI_Refresh_Token';
    private readonly _refreshTokenExpKey = 'DSI_Refresh_Token_Exp';
    private readonly _accessTokenKey = 'DSI_Access_Token';
    private readonly _defaultAccessAndIdTokenExpirationMinutes = 60;
    private readonly _defaultRefreshTokenExpirationDays = 180;
    private readonly _daysToRenewBeforeRefreshTokenExpires = this._defaultRefreshTokenExpirationDays * 0.5;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': this.configService.config.apiKey,
    });

    constructor(
        private readonly cookieService: DaySmartCookieService,
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigService
    ) {}

    public getTokens(): Observable<Tokens> {
        return new Observable<Tokens>((subscriber) => {
            const idToken = this.cookieService.get(this._idTokenKey);
            const accessToken = this.cookieService.get(this._accessTokenKey);
            const refreshToken = this.cookieService.get(this._refreshTokenKey);
          if (!idToken || !accessToken) {
            if (!refreshToken) {
                    subscriber.next();
                    subscriber.complete();
            } else {
                    this.refreshIdAndAccessTokens(refreshToken).subscribe(({ refreshedIdToken, refreshedAccessToken }) => {
                        this.saveIdTokenAndAccessTokensToCookie(refreshedIdToken, refreshedAccessToken);
                        subscriber.next({
                            idToken: refreshedIdToken,
                            accessToken: refreshedAccessToken,
                            refreshToken,
                        });
                        subscriber.complete();
                    });
                }
            } else {
                subscriber.next({
                    idToken,
                    accessToken,
                    refreshToken,
                });
                subscriber.complete();
            }
        });
    }

    public getEmailFromIdToken(idToken: string): any {
        const jsonTokenData = this.GetPropertiesFromIdToken(idToken);
        return jsonTokenData.email;
    }
   
    public getCustomerIdFromIdToken(idToken: string): any {
        const jsonTokenData = this.GetPropertiesFromIdToken(idToken);
        return +jsonTokenData['custom:customerId'];
    }

    private GetPropertiesFromIdToken(idToken: string) {
        const idTokenArray = idToken.split('.');
        if (idTokenArray.length !== 3) {
            throw new Error('Unexpected ID Token');
        }
        const tokenData = idTokenArray[1];
        const decodedTokenData = atob(tokenData);
        const jsonTokenData = JSON.parse(decodedTokenData);
        return jsonTokenData;
    }

  private refreshIdAndAccessTokens(refreshToken: string): Observable<{ refreshedIdToken: string; refreshedAccessToken: string }> {
    const body = {
          refreshToken: refreshToken,
      };
     const refreshTokenExp = this.cookieService.get(this._refreshTokenExpKey);
    if (!refreshTokenExp || moment(refreshTokenExp) < moment().add(this._daysToRenewBeforeRefreshTokenExpires, 'day')) {
            this.logout();
            return of();
        }
        const url = `${this.configService.config.apiUrl}/refreshAccess`;
        return this.httpClient.post(url, body, { headers: this.headers }).pipe(
            catchError((err) => {
                this.logout();
                return throwError(() => err);
            }),
            map((response: any) => {
                return {
                    refreshedIdToken: response.idToken,
                    refreshedAccessToken: response.accessToken,
                };
            })
        );
    }

    public login(
        email: string,
        password: string,
        customerId: number
    ): Observable<{
        idToken: string;
        accessToken: string;
        refreshToken: string;
    }> {
        const url = `${this.configService.config.apiUrl}/authenticateUser`;
        const body = {
            email: email,
            password: password,
            customerId: customerId,
        };
        return this.httpClient.post(url, body, { headers: this.headers }).pipe(
            map((res: any) => {
                return {
                    idToken: res.idToken,
                    accessToken: res.accessToken,
                    refreshToken: res.refreshToken,
                };
            })
        );
    }

    public logout(): void {
        this.clearAllCookies();
    }

    public clearAllCookies(): void {
        const domain = this.cookieService.getDomain();
        this.cookieService.deleteAll('/', domain, true, 'None');
    }

    public saveAllTokensToCookie(idToken: string, accessToken: string, refreshToken: string, domain?: string): void {
        if (!domain) {
            domain = this.cookieService.getDomain();
        }
        const refreshExpiration = moment().add(this._defaultRefreshTokenExpirationDays, 'day');
        const refreshDate = refreshExpiration.format('YYYY-MM-DD[T]HH:mm:ss');
        this.saveIdTokenAndAccessTokensToCookie(idToken, accessToken);
        this.cookieService.set(this._refreshTokenKey, refreshToken, refreshExpiration.toDate(), '/', domain, true, 'None');
        this.cookieService.set(this._refreshTokenExpKey, refreshDate, refreshExpiration.toDate(), '/', domain, true, 'None');
    }

    private saveIdTokenAndAccessTokensToCookie(idToken: string, accessToken: string, domain?: string): void {
        if (!domain) {
            domain = this.cookieService.getDomain();
        }
        const expiration = moment().add(this._defaultAccessAndIdTokenExpirationMinutes, 'minute');
        this.cookieService.set(this._idTokenKey, idToken, expiration.toDate(), '/', domain, true, 'None');
        this.cookieService.set(this._accessTokenKey, accessToken, expiration.toDate(), '/', domain, true, 'None');
    }
}
