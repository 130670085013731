import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class DaySmartCookieService extends CookieService {
    public get isClientSecure(): boolean {
        return location.protocol.toLowerCase() === 'https:';
    }
    public getDomain(): string {
        let domain = location.host;
        if (domain.indexOf('.') > -1) {
            domain = domain.substring(domain.indexOf('.'));
        } else if (domain.indexOf(':') > -1) {
            domain = domain.substring(0, domain.indexOf(':'));
        }
        return domain;
    }
}
