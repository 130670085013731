import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, delay, forkJoin, of, tap } from 'rxjs';
import { AuthTokenService, Tokens } from '../../services/authToken/auth-token.service';
import { PostMessageService } from '../../services/postMessage/post-message.service';

export enum FormState {
    LoginForm = 0,
    LoggedInPrompt = 1,
    Loading = 2,
    Error = 3,
}
export enum PromptForExistingTokensChoice {
    Logout = 0,
    Continue = 1,
}

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrl: './sso-login.component.scss',
})
export class SsoLoginComponent implements OnInit, AfterViewInit {
    public formGroup = this.fb.group({
        emailAddress: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
    });
    public isLoading = true;
    public loggedInUserEmail = '';
    private _cid = 0;
    private _isTemporary = false;
    private _saveTokens = true;
    private _promptForExistingTokens = false;
    private _removeExistingTokens = false;
    private _working = false;
    public get working(): boolean {
        return this._working;
    }
    public set working(value: boolean) {
        if (value) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
        this._working = value;
    }
    public errorMessage = '';
    public FormState = FormState;
    private _formState: FormState = FormState.Loading;
    public get formState(): FormState {
        return this._formState;
    }
    public set formState(state : FormState) {
        this._formState = state;
    }
    
    public PromptForExistingTokensChoice = PromptForExistingTokensChoice;
    public promptForExistingTokensChoice: PromptForExistingTokensChoice | undefined;
    private readonly PROMPT_FOR_EXISTING_TOKENS_ACTION_DELAY_MILLISECONDS = 1000;
    public isDaySmartBookingLogin: boolean = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthTokenService,
        private postMessageService: PostMessageService,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.loggedInUserEmail = '';
        this.promptForExistingTokensChoice = undefined;
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams) {
            this._isTemporary = queryParams['isTemporary'] === '1';
            this._saveTokens = queryParams['saveTokens'] !== '0';
            this._promptForExistingTokens = queryParams['promptForExistingTokens'] === '1';
            this._removeExistingTokens = queryParams['removeExistingTokens'] === '1';
            this.isDaySmartBookingLogin = queryParams['application'] === 'dsb';
        }
        this._cid = this.getCustomerId();
        if (!this._cid) {
            this.formState = FormState.Error;
            this.errorMessage = 'Customer ID must be provided.';
            return;
        }
        if (this._isTemporary) {
            this.formState = FormState.LoginForm;
            this.isLoading = false;
            return;
        }
        if (this._removeExistingTokens) {
            this.authService.clearAllCookies();
            this.formState = FormState.LoginForm;
            this.isLoading = false;
            return;
        }
        this.authService.getTokens().subscribe((tokens: Tokens) => {
            if (tokens?.idToken && tokens.accessToken && tokens.refreshToken) {
                if (this._cid !== this.authService.getCustomerIdFromIdToken(tokens.idToken)) { 
                    this.authService.clearAllCookies();
                    this.formState = FormState.LoginForm;
                    this.isLoading = false;
                    return;
                }
                if (!this._promptForExistingTokens) {
                    this.postMessageService.postSuccess(tokens);
                    return;
                }
                this.loggedInUserEmail = this.authService.getEmailFromIdToken(tokens.idToken);
                if (this.loggedInUserEmail) {
                    this.formState = FormState.LoggedInPrompt;
                }
            }
            this.isLoading = false;
            if (this.formState === FormState.Loading) { 
                this.formState = FormState.LoginForm;
            }
        });
    }

    ngAfterViewInit(): void {
        this.applyStyleForDSB();
    }

    private applyStyleForDSB() {
        const themeColor = this.route.snapshot.queryParamMap.get('color') ?? null;

        if (this.isDaySmartBookingLogin && themeColor) {
            document.documentElement.style.setProperty('--theme-color', themeColor);

            const ssoLoginContainer = this.el.nativeElement.querySelector('#ssologin-container');
            if (ssoLoginContainer) {
                this.renderer.addClass(ssoLoginContainer, 'daysmart-booking-style-class');
            }
        }
    }

    public login(): void {
        this.errorMessage = '';
        const customerID = this.getCustomerId();
        if (this.formGroup.valid) {
            this.working = true;
            this.authService
                .login(this.formGroup.value.emailAddress!, this.formGroup.value.password!, customerID)
                .pipe(
                    tap((tokens) => {
                        if (!this._isTemporary && this._saveTokens) {
                            this.authService.saveAllTokensToCookie(tokens.idToken, tokens.accessToken, tokens.refreshToken);
                        }
                        this.postMessageService.postSuccess(tokens);
                    }),
                    catchError((err) => {
                        this.working = false;
                        this.errorMessage = err.error?.errorMessage || err.error?.message;
                        this.postMessageService.postError(new Error(this.errorMessage));
                        return of();
                    })
                )
                .subscribe();
        } else {
            this.formGroup.markAllAsTouched();
        }
    }

    private getCustomerId(): number {
        const customerIdStr = this.route.snapshot.queryParamMap.get('customerID') ?? '';
        const customerId = parseInt(customerIdStr);
        return !isNaN(customerId) ? customerId : 0;
    }

    public cancel(): void {
        this.postMessageService.postCancel();
    }

    public logout(): void {
        this.promptForExistingTokensChoice = PromptForExistingTokensChoice.Logout;
        this.working = true;
        this.authService.logout();
        of(undefined)
            .pipe(delay(this.PROMPT_FOR_EXISTING_TOKENS_ACTION_DELAY_MILLISECONDS))
            .subscribe(() => {
                this.loggedInUserEmail = '';
                this.working = false;
                this.formState = FormState.LoginForm;
            });
    }

    public continue(): void {
        this.promptForExistingTokensChoice = PromptForExistingTokensChoice.Continue;
        this.working = true;
        forkJoin({
            delayResult: of(undefined).pipe(delay(this.PROMPT_FOR_EXISTING_TOKENS_ACTION_DELAY_MILLISECONDS)),
            getTokensResult: this.authService.getTokens(),
        }).subscribe(({ getTokensResult }) => {
            if (getTokensResult?.idToken && getTokensResult?.accessToken && getTokensResult?.refreshToken) {
                this.postMessageService.postSuccess(getTokensResult);
            } else {
                const err = new Error(`Continue login for ${this.loggedInUserEmail} failed to get tokens.`);
                this.working = false;
                this.errorMessage = err.message;
                this.postMessageService.postError(err);
            }
        });
    }
}
