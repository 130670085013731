import { AfterViewInit, Component, HostListener } from '@angular/core';
import { AuthTokenService } from '@app/services/authToken/auth-token.service';
import { PostMessageService } from '@app/services/postMessage/post-message.service';

@Component({
  selector: 'app-update-tokens',
  template: `<div></div>`,
})
export class UpdateTokensComponent implements AfterViewInit {

  constructor(
    private authService: AuthTokenService,
    private postMessageService: PostMessageService
  ) { }

  ngAfterViewInit(): void {
    this.postMessageService.postSuccess('Ready');
  }

  @HostListener('window:message', ['$event'])
  public onMessageReceived(event: any): void {
    if (event?.data?.idToken && event?.data?.accessToken && event?.data?.refreshToken) {
      this.authService.saveAllTokensToCookie(event.data.idToken, event.data.accessToken, event.data.refreshToken);
    }
  }
}
