import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.md-module';
import { SsoLoginComponent } from './features/sso-login/sso-login.component';
import { UpdateTokensComponent } from './features/update-tokens/update-tokens.component';
import { AuthTokenService } from './services/authToken/auth-token.service';
import { ConfigService } from './services/config/config.service';
import { DaySmartCookieService } from './services/daySmartCookie/day-smart-cookie.service';
import { PostMessageService } from './services/postMessage/post-message.service';
import { CheckTokensComponent } from './features/check-tokens/check-tokens.component';

@NgModule({
    declarations: [AppComponent, SsoLoginComponent, UpdateTokensComponent, CheckTokensComponent],
    imports: [
        AppRoutingModule,
        AppMaterialModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        AuthTokenService,
        DaySmartCookieService,
        PostMessageService,
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => () => configService.initialize(),
            deps: [ConfigService],
            multi: true,
        },],
    bootstrap: [AppComponent],
})
export class AppModule {}
