import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthTokenService, Tokens } from '@app/services/authToken/auth-token.service';
import { MessageType, PostMessageService } from '@app/services/postMessage/post-message.service';

@Component({
  selector: 'app-check-tokens',
  template: `<div></div>`,
})
export class CheckTokensComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthTokenService,
    private postMessageService: PostMessageService
  ) { }

  ngOnInit(): void {
    const paramMap = this.route.snapshot.paramMap;
    if (paramMap) {
      const cid = paramMap.get('CID') || null;
      if (!cid) {
        //If CID parameter is not found from route query params then post error with data: undefined
        this.handlePostMessage(MessageType.error, undefined);
      } else {
        this.authService.getTokens().subscribe((tokens: Tokens) => {
          if (tokens && tokens.idToken) {
            this.handleTokens(tokens, cid);
          } else {
            //If idToken is not found in the cookie then post success with data: undefined
            this.handlePostMessage(MessageType.success, undefined);
          }
        });
      }
    }
  }

  private handleTokens(tokens: Tokens, cid: string): void {
    const idToken = tokens.idToken || '';
    const customerId: string = this.authService.getCustomerIdFromIdToken(idToken);
    if (cid?.toLowerCase() == customerId?.toString()?.toLowerCase()) {
      const data = {
        idToken: tokens.idToken,
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
      };
      this.handlePostMessage(MessageType.success, data);
    } else {
      //If CID from route query params does not match with customer ID from cookie (idToken) then post success with data: undefined
      this.handlePostMessage(MessageType.success, undefined);
    }
  }

  private handlePostMessage(messageType: string, messageData: any): void {
    if (messageType == MessageType.success) {
      this.postMessageService.postSuccess(messageData);
    } else if (messageType == MessageType.error) {
      this.postMessageService.postError(messageData);
    }
  }
}
