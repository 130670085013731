import { Injectable } from '@angular/core';

export interface PostMessagePayload {
    result: typeof MessageType.success | typeof MessageType.cancel | typeof MessageType.error;
    data?: any;
}

export const MessageType = {
    success: 'success',
    cancel: 'cancel',
    error: 'error',
} as const;

@Injectable({
    providedIn: 'root',
})
export class PostMessageService {
    public postSuccess(data: any): void {
        this.postMessage({ result: MessageType.success, data });
    }
    public postCancel(): void {
        this.postMessage({ result: MessageType.cancel });
    }
    public postError(data: any): void {
        this.postMessage({ result: MessageType.error, data });
    }

  private postMessage(payload: PostMessagePayload, targetOrigin = '*'): void {
        let parentWindow: Window = window;

        // if the current window is a popup, window.parent will just point to itself instead of the expected parent
        if (window.parent && window.parent !== window) {
            parentWindow = window.parent; // in an iframe
        } else if (window.opener) {
            parentWindow = window.opener; // in a popup
        }
        parentWindow.postMessage(payload, targetOrigin);
    }
}
