import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface IConfig {
    production: boolean;
    apiUrl: string;
    apiKey: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
    private readonly CONFIG_URL = 'assets/config.json';
    private readonly CONFIG_LOAD_TIMEOUT = 3 * 1000;
    private cachedConfig!: IConfig;

    public get config(): IConfig {
        return this.cachedConfig;
    }

    constructor(private http: HttpClient) { }
    
    public initialize(): Promise<IConfig> {
        return lastValueFrom(this.http.get<IConfig>(this.CONFIG_URL).pipe(
            // throw an error if it takes too long to fetch the config.json file
            timeout(this.CONFIG_LOAD_TIMEOUT),
            catchError(() => {
                //fallback to the prod config if something went wrong
                return of(environment);
            }),
            tap((config) => {
                this.cachedConfig = config;
            })
        ));
    }
}
