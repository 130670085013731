import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTokensComponent } from './features/check-tokens/check-tokens.component';
import { SsoLoginComponent } from './features/sso-login/sso-login.component';
import { UpdateTokensComponent } from './features/update-tokens/update-tokens.component';

const routes: Routes = [
    {
        path: '',
        component: SsoLoginComponent,
    },
    {
        path: 'UpdateTokens',
        component: UpdateTokensComponent,
    },
    {
        path: 'CheckTokens/:CID',
        component: CheckTokensComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
