<div id="ssologin-container">
    <div *ngIf="formState === FormState.Loading" class="loading">Loading...</div>
    <div *ngIf="formState === FormState.Error" class="row error">{{errorMessage}}</div>
    <div *ngIf="formState === FormState.LoggedInPrompt" class="logged-in-prompt">
        <div class="row">
            <span class="email">{{ loggedInUserEmail }}</span>
            <br />
            is already logged in.
        </div>
        <div class="row error">{{ errorMessage }}</div>
        <div class="row buttons">
            <button
                *ngIf="!working || promptForExistingTokensChoice === PromptForExistingTokensChoice.Continue"
                [disabled]="working"
                mat-raised-button
                color="accent"
                (click)="logout()"
                data-qa="log-out-btn"
            >
                Log Out
            </button>
            <button
                *ngIf="working && promptForExistingTokensChoice === PromptForExistingTokensChoice.Logout"
                mat-raised-button
                color="accent"
                data-qa="log-out-btn"
            >
                <mat-spinner [diameter]="30"></mat-spinner>
            </button>
            <button
                *ngIf="!working || promptForExistingTokensChoice === PromptForExistingTokensChoice.Logout"
                [disabled]="working"
                mat-raised-button
                color="primary"
                (click)="continue()"
                data-qa="continue-btn"
            >
                Continue
            </button>
            <button
                *ngIf="working && promptForExistingTokensChoice === PromptForExistingTokensChoice.Continue"
                mat-raised-button
                color="primary"
                data-qa="continue-btn"
            >
                <mat-spinner [diameter]="30"></mat-spinner>
            </button>
        </div>
    </div>
    <form *ngIf="formState === FormState.LoginForm" [formGroup]="formGroup">
        <div class="row">
            <label
                *ngIf="isDaySmartBookingLogin"
                [ngClass]="{
                    'error-color':
                        (formGroup.get('emailAddress')?.errors?.['required'] || formGroup.get('emailAddress')?.errors?.['email']) &&
                        formGroup.get('emailAddress')?.touched
                }"
                >Email</label
            >
            <mat-form-field
                appearance="outline"
                data-qa="email_address_field"
                [ngClass]="{
                    'error-color':
                        (formGroup.get('emailAddress')?.errors?.['required'] || formGroup.get('emailAddress')?.errors?.['email']) &&
                        formGroup.get('emailAddress')?.touched
                }"
            >
                <mat-label *ngIf="!isDaySmartBookingLogin">Email Address</mat-label>
                <input matInput type="email" formControlName="emailAddress" />
            </mat-form-field>
            <mat-error
                *ngIf="formGroup.get('emailAddress')?.errors?.['required'] && formGroup.get('emailAddress')?.touched"
                class="row error"
            >
                Email required
            </mat-error>
            <mat-error *ngIf="formGroup.get('emailAddress')?.errors?.['email'] && formGroup.get('emailAddress')?.touched" class="row error">
                Please enter valid email address.
            </mat-error>
        </div>
        <div class="row">
            <label
                *ngIf="isDaySmartBookingLogin"
                [ngClass]="{
                    'error-color': formGroup.get('password')?.errors?.['required'] && formGroup.get('password')?.touched
                }"
                >Password</label
            >
            <mat-form-field
                appearance="outline"
                data-qa="password_field"
                [ngClass]="{
                    'error-color': formGroup.get('password')?.errors?.['required'] && formGroup.get('password')?.touched
                }"
            >
                <mat-label *ngIf="!isDaySmartBookingLogin">Password</mat-label>
                <input matInput type="password" formControlName="password" />
            </mat-form-field>
            <mat-error *ngIf="formGroup.get('password')?.errors?.['required'] && formGroup.get('password')?.touched" class="row error">
                Password required
            </mat-error>
        </div>
        <mat-error class="row error">{{ errorMessage }}</mat-error>
        <div class="row buttons">
            <button
                *ngIf="!isDaySmartBookingLogin"
                [disabled]="working"
                mat-raised-button
                color="accent"
                (click)="cancel()"
                data-qa="cancel-btn"
            >
                Cancel
            </button>
            <button *ngIf="!working" id="log-in-button" mat-raised-button color="primary" (click)="login()" data-qa="log-in-btn">
                Log In
            </button>
            <button *ngIf="working" mat-raised-button color="primary" data-qa="log-in-btn">
                <mat-spinner [diameter]="30"></mat-spinner>
            </button>
        </div>
    </form>
</div>
